body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* for popup (push notification) */
/* body #onesignal-slidedown-container #onesignal-slidedown-dialog .slidedown-body-message {
  font-family: 'Gill Sans Nova' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20.08px !important;
  letter-spacing: 0.06em !important;
  color: #ea0cd6 !important;
}

body #onesignal-slidedown-container {
  top: 50% !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
 
body #onesignal-slidedown-dialog {
  transform: translateY(-50%) !important;
  border-radius: 10px !important;
}
 
body .slidedown-body {
  padding-bottom: 20px !important;
}
 
body .slidedown-body-message {
  font-family: 'Gill Sans Nova' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20.08px !important;
  letter-spacing: 0.06em !important;
  color: #71ea0c !important;
}
 
body .slidedown-button.primary {
  font-size: 14px !important;
  font-weight: 900 !important;
  text-transform: none !important;
  font-family: "Gill Sans Nova" !important;
  color: #ffffff !important;
  background-color: #e66433 !important;
  border-radius: 50px !important;
  padding: 8px 25px !important;
}
 
body .slidedown-button.secondary {
  font-size: 14px !important;
  font-weight: 900 !important;
  text-transform: none !important;
  font-family: "Gill Sans Nova" !important;
  color: #ffffff !important;
  background-color: #BCBEC0 !important;
  border-radius: 50px !important;
  padding: 8px 25px !important;
} */


.cropper-view-box {
  border-radius: 10px !important; 
  border: 1px solid #44C8F5 !important; 
}

.customCropBox .cropper-face {
  border-radius: 30px; 
  background-color: rgba(255, 255, 255, 0.5); 
  border: none; 
}

.customCropBox .cropper-line,
.customCropBox .cropper-point.point-se,
.customCropBox .cropper-point.point-sw,
.customCropBox .cropper-point.point-nw,
.customCropBox .cropper-point.point-ne {
  border-radius: 50%; 
  background-color: #44C8F5; 
}

.customCropBox .cropper-move {
  border-radius: 30px; 
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px dashed #FF5733; 
}