.header {
  //styleName: Heading;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 900 !important; // Updated weight
  letter-spacing: 0.02em !important; // Updated letter spacing to 2%
  color: #E66433 !important;
  font-size: 14px !important; // Updated size
  line-height: 18.22px !important; // Updated line height
}

.daterHeader{
  margin-top: 0px !important;
  margin-bottom: -10px !important;
}
.subheader {
  font-family: 'Gill Sans Nova' !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 12px !important;
  letter-spacing: 0.12em !important; // Adjusted to 12%
  color: #BCBEC0 !important;
  margin-top: 14px !important;
  margin-bottom: 15px !important;
}

@media (width>=1800px) {
  .header {
    font-size: 40px !important;
  }
  .subheader {
    font-size: 22px !important;
  }
 
}

@media (width>=900px) {
  .daterHeader{
    margin-top: 40px !important;
  }
}
