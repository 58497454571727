.header {
  //   background-color: #ffffff !important;
  background-color: #000000 !important;
  box-shadow: none !important;
}
.appIcon {
  padding-top: 24px;
  padding-bottom: 24px;
}
.menuContainer {
  flex-direction: row-reverse;
  background-color: #000000 !important;
}
.menuItem {
  //   color: #33196b !important;
  // color: #ffffff !important;
  background-color: #000000 !important;
  // color: #fff !important;
  color: #fff !important;
  font-family: Poppins, sans-serif !important;
  // font-size: 20px !important;
  font-weight: 600 !important;
  // line-height: 24px !important;
  letter-spacing: 0.03em !important;
}
@media (width>=1800px) {
  .menuItem {
    font-size: 30px !important;
  }
  .btnJoin {
    font-size: 30px !important;
  }
}
.btnJoin {
  border-radius: 24px !important;
  border: 2px solid #ff9933 !important;
  // width: 101px !important;
  //   color: #33196b !important;
  // color: #ffffff !important;
  color: #fff !important;
  font-family: Poppins, sans-serif !important;
  //font-size: 20px !important;
  font-weight: 600 !important;
  // line-height: 24px !important;
  letter-spacing: 0.03em !important;
  text-transform: none !important;
}
.leftMenuAppIcon {
  cursor: pointer !important;
}
