.header {
  //styleName: Heading S 3;
  // // font-family: "Knuckle Down" !important;
  // font-family: 'Myriad Pro' !important;
  // font-weight: 400 !important;
  // letter-spacing: 0em !important;
  // // color: #FF000A !important;
  // color: #76d9ff;
  // font-size: 32px !important;
color: #E66433;
  font-family: 'Gill Sans Nova' !important;
font-size: 14px !important;
font-weight: 900 !important;
line-height: 18.22px !important;
letter-spacing: 0.02em !important;

}

.subheader {
  font-family: 'Myriad Pro' !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  font-size: 20px !important;
  //color: #05232E !important;
  color: #fff !important;
}

@media (width>=1800px) {
  .header {
    font-size: 16px !important;
  }
  .subheader {
    font-size: 32px !important;
  }
}
