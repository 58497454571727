.name {
  // color:  #000 !important;
  color: #fff !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  text-align: center !important;
font-weight: 900 !important;
line-height: 16.22px !important ;
text-align: left;

}

.message {
  font-family: 'Gill Sans Nova' !important;
  font-weight: 500 !important;
  // line-height: normal !important;
  letter-spacing: 0em !important;
  font-size: 17px !important;
  // color: #fff !important;
  line-height: 16px !important;
  letter-spacing: 0.01em !important;
  overflow-wrap: break-word;
}

.time {
  font-size: 12px !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  line-height: 14.31px !important;
  letter-spacing: 0.05em !important;
  color: #7f8082 !important;
  text-align: left;
}

.btnCreateAccount {
  // padding: 12px 32px !important;
  border-radius: 25px !important;
  border: 1px solid #E72933 !important;
  font-family: 'Gill Sans Nova' !important;
  //  font-size: 18px !important;
  font-style: normal !important;
  letter-spacing: 4% !important;
  font-weight: 900 !important;
  //  line-height: 24px !important; /* 133.333% */
  text-transform:none !important;
}


.writeamessage {
  // color: #8d8d8d !important;
  color: #7f8082 !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  font-size: 10px !important;
  line-height: 11.48px;
  letter-spacing: 0.02em;
}

.inputField {
  background-color: #e7e7e7 !important;
  border-radius: 25px !important;
  border: 1px solid #cacaca !important;
}

.back {
  // color:  #00597B !important;
  color: #cccccc !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase;
  // padding-left: 10px !important;
  // padding-bottom: 5px !important;
  cursor: pointer;

  line-height: 17.21px;
  letter-spacing: 0.1em;
}
.logoBack{
  color: #cccccc;
  height: 12px !important;
  width: 12px !important;
}

.daterButton{
  // background-color: #E66433 !important;
  border-radius: 25px !important;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  font-size: 12px !important; 
  color: #FFFFFF !important;
  padding: 8px 25px !important;
  line-height: 16px !important;
}

.friendButton{
  // background-color: #2BB673 !important;
  border-radius: 25px !important;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  font-size: 12px !important; 
  color: #FFFFFF !important;
  padding: 8px 33px !important;
  line-height: 16px !important;
}

.centerText{
  font-family:'Gill Sans Nova' !important;
font-size: 14px !important;
font-weight: 900 !important;
line-height: 15.62px !important;
text-align: left;
color: #E66433 !important;
align-self: center;
}

.firstSec {
  // display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  .firstPart {
    display: flex;
    // direction: row;
    align-items: center;
    gap: 5px;
  }
}
// @media (width<=600px) {
//   .msgContainer {
//     padding: 10px 15px 20px 10px;
//     position: fixed;
//     bottom: 60px;
//     height: 80px;
//     background: black;
//     margin-bottom: 0px;
//   }
// }
