.container {
  //   background-color: #ffffff;
  // background-color: #161414e0;
  // background-color: #28252587;
  // background-color: #000;
  padding: 100px 24px 40px 24px;
  color: #fff !important;
  // padding: 48px;
  // gap: 24px;
  // max-width: 600px !important;
  // max-height: 80vh !important;
  overflow: auto;
}
@media (width <=400px) {
  .container {
    padding: 100px 10px 10px 10px !important;
  }
}
