.iphone-lower-div {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

    .div-1-parent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .div-1 {
        // display: flex;
        // flex-direction: column;
        // gap: 10px;

        .phone-step-1-head {
            font-family: Inter;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0em;
            color: #FFFFFF;
        }

        .div-11 {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
        }

        .div-12 {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: center;
            color: #22A887;
            margin-left: 10px;
        }

        .div-122 {
            font-family: Inter;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: #22A887;
        }
    }

    .div-2 {
        img {
            height: 91.07px;
            width: 32px;
        }
    }
}

.div-3 {
    img {
        width: 100%;
        height: 120px;
    }
}

.phone-step-1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .phone-step-1-head {
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
    }

    .phone-step-1-desc {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
    }
}

.install-img-1 {
    img {
        width: 100%;
    }
}