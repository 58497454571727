.footer {
  text-align: center;
  color: #33196b;
  position: fixed;
  bottom: 0;
  gap: 10px;
  align-items: center;
  // background-color: #FFFFFF;
  background-color: #000;
  height: auto;
  padding-top: 7px;
  width: 100%;
  margin-left:"20px" !important;
  text-transform: uppercase;
  font-family: 'Gill Sans Nova' !important;
}
.footerLink {
  text-transform: none;
  text-decoration: none;
  // color: #33196B;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Gill Sans Nova' !important;  font-size: 12px;
  padding-right: 6%;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.05em;
}
