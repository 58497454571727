.heading{
    //styleName: Heading S 2;
font-family: 'Gill Sans Nova' !important;
font-weight: 900 !important;
letter-spacing: 0em !important;
text-align: center !important;
color:#E72933 !important;
margin-top: 20px;
}

.subHeading{
    font-family: 'Gill Sans Nova' !important;
    font-weight: 500 !important;
    letter-spacing: 0em !important;
    text-align: justify !important;
    line-height: 14px !important;
    text-transform: uppercase;
    color: #44C8F5;
    letter-spacing: 0.1em !important;
}

.button{
    // padding-left: 10px !important;
    // padding-right: 10px !important;
    display: flex !important;
    border-radius: 25px !important;
   // padding: 12px 32px !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'Gill Sans Nova' !important;
   // font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
   // line-height: 24px !important; /* 133.333% */
    text-transform: capitalize !important;  
    color: #000000 !important;
    margin: 10px;
}
.primary{
    background-color: #E66433 !important;
    color: #fff !important;
    font-size: 14px;
}
.secondary{
    background-color: #BCBEC0 !important;
    color: #fff !important;
    font-size: 14px;
}
.customMessage{
    padding-top: 15px !important;
    padding-bottom: 60px !important;
    background-color: #000000;
    color: #D4D4D4 !important;
    border-radius: 6px;
    z-index: 1;
    font-family: 'Gill Sans Nova' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    min-height: 110px !important;
}