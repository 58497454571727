.switchRoot{
    display: flex !important;
    position: relative !important;
    align-items: center !important;
    cursor: pointer !important;
    // width: 0 !important;
    // z-index: 100 !important;
}
.labelLeft{
    position:absolute !important;
    z-Index: 1 !important;
    color: #ffffff !important;
    font-size: 12px !important;
   // width: 70px !important;
    word-wrap:normal !important;
   // left: 6px !important;
    font-family: 'Gill Sans Nova' !important;
   // font-size: 12px !important;
    font-weight: 500 !important;
   // line-height: 14px !important;
    letter-spacing: 0.5px !important;
    text-align: left !important;

}
.labelRight{
    //left: 35px !important;
    position:absolute !important;
    z-Index: 1 !important;
    color: #ffffff !important;
    
   // width: 70px !important;
    word-wrap: unset !important;
    font-family: 'Gill Sans Nova' !important;
   // font-size: 12px !important;
    font-weight: 500 !important;
  //  line-height: 14px !important;
    letter-spacing: 0.5px !important;
    text-align: left !important;
  }

  @media (width>=1800px){
    // .labelLeft{
    //   font-size: 18px !important;
    // }
  }