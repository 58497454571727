.cropper-view-box{
    border-radius: 50%
}
// .cropper-line{
//     pointer-events: none !important;
// }

.cropper-point{
    pointer-events: none !important;
}
.description{
    color: #F46B1B !important;

    /* Heading S 2 */
    font-family: 'Knuckle Down' !important;
    //font-size: 32px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.button{
    margin-top: 30px;
    display: flex !important;
    border-radius: 25px !important;
    padding: 12px 32px !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'Gill Sans Nova' !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
   // line-height: 24px !important; /* 133.333% */
    text-transform: capitalize !important;  
    color: #000000 !important;
}
.primary{
    background-color: #F93 !important;
    color: #FFF !important;
}
.secondary{
    background-color: #BCBEC0 !important;
    color: #FFF !important;
}

/* CropperStyles.module.css */
.customCropBox .cropper-view-box {
    border-radius: 30px !important; 
    border: 2px solid #FF5733 !important; /* Custom border color for the viewbox */
   
  }
  
  .customCropBox .cropper-face {
    border-radius: 30px; 
    background-color: rgba(255, 255, 255, 0.5); 
    border: none; 
  }
  
  .customCropBox .cropper-line,
  .customCropBox .cropper-point.point-se,
  .customCropBox .cropper-point.point-sw,
  .customCropBox .cropper-point.point-nw,
  .customCropBox .cropper-point.point-ne {
    border-radius: 50%; 
    background-color: #44C8F5; 
  }
  
  .customCropBox .cropper-move {
    border-radius: 30px; 
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px dashed #FF5733; 
  }
  