.mobStickyFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  // background-color: #f8f8ff;
  background-color: #000000;
  color: #fff !important;
  // display: flex;
  align-items: center;
  justify-content: space-between;
  // height: 81px;
  height: auto;
  padding-bottom: 10px;
  //margin-bottom: 10px;
  // z-index: 99900;
  z-index: 1299; //reduced the z-index for fix the select drop down issue for dating preference
  ul {
    display: flex;
    justify-content: space-evenly;
    gap: 5px;
    width: 100%;
    li {
      div {
        justify-content: center;
      }
    }
  }
  .menuContainer {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    li {
      max-width: 20%;
      padding-right: 0px !important;
      padding-left: 0px !important;
      display: flex;
      align-items: flex-start;
      img {
        // width: 22px !important;
        // height: 22px !important;
        padding-top: 4px !important;
      }
      p {
        font-size: 12px !important;
      }
    }
  }
}
