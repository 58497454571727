.main {
  // background-color: #E7E7E7 !important;
  color: #000 !important;
  min-height: 100vh !important;
}
.container {
  // height: 395px;
  //   background-color: #ffffff;
  // background-color: #4f4e4e !important;
  background-color: #000 !important;
  padding: 20px;
  // padding-bottom: 48px;
  gap: 24px;
  max-width: 600px !important;
}
