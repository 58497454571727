.heading {
  //styleName: Heading S 2;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 900 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  // color: #00597b !important;
  color: #E72933 !important;
}

.subHeading {
  font-family: 'Gill Sans Nova' !important;
    font-weight: 500 !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    line-height: 14px !important;
    text-transform: uppercase;
}

.button{
  // padding-left: 10px !important;
  // padding-right: 10px !important;
  display: flex !important;
  border-radius: 25px !important;
 // padding: 12px 32px !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: 'Gill Sans Nova' !important;
 // font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
 // line-height: 24px !important; /* 133.333% */
  text-transform: capitalize !important;  
  color: #000000 !important;
  margin: 10px;
}
.primary {
  //   background-color: #f93 !important;
  background-color: #2BB673 !important;
  //   color: #000 !important;
  color: #fff !important;
}

.secondary{
  background-color: #BCBEC0 !important;
  color: #fff !important;
  font-size: 14px;
}