.nameAddressHeader {
  // color: #444 !important;
  color: #fff !important;
  text-align: left !important;
  /* Heading */
  // font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  font-size: 24px !important;
}

.name {
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  color: #e66433 !important;
  text-align: left !important;
  margin-left: 25px;
  line-height: 20.83px;
}

.ageAddress {
  font-family: 'Gill Sans Nova' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #cccccc !important;
  text-align: left !important;
  line-height: 17.21px;
  letter-spacing: 0.02em;
}


.gap {
  gap: 20px;
}
.activeBtn {
  display: flex;
  justify-content: flex-end;
}

@media (width<=1200px) {
  .myProfileHeader {
    display: flex;
    justify-content: left;
    gap: 5px;
    align-items: center;
  }
}
@media (width>=1800px) {
  .nameAddressHeader {
    font-size: 32px !important;
  }
}
