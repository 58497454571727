.question{
    font-family: 'Gill Sans Nova' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: normal !important;
    font-size: 18px !important;              
    letter-spacing: 2% !important;                                 
}
@media (width>=1800px){
    .question{
        font-size: 32px !important;
    }
}