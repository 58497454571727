.root {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  justify-content: space-between;
}

.firstDiv {
  // display: flex;
  gap: 10px;
  // width: 60%;
}

.daterConvo {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}

.root:hover {
  background-color: #5c5b5f !important;
}

.profileName {
  // color: #000 !important;
  color: #ffffff !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 15.62px !important;
  font-size: 12px !important;
}

.profileNameConvo {
  // color: #000 !important;
  color: #ffffff !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 14.32px !important;
  font-size: 12px !important;
  max-width: 104px !important;
  overflow-wrap: break-word;
}

.dater_profileName {
  // color: #000 !important;
  color: #fff !important;
  // width: 80px;
  /* Heading */
  text-align: center;
  font-family: 'Gill Sans Nova', sans-serif !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 15.62px !important;
  font-size: 12px !important;
  max-width: 70px;  /* Set width to 70px */
  word-wrap: break-word;  /* Break words that are too long */
  word-break: break-word; /* Ensure even single long words break to the next line */
  // margin-left: 30px;
  // margin-top: 5px;
}


.chatWith,
.friendOf {
  // margin-left: -40px;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 14.34px !important;
  letter-spacing: 2px !important;
  text-wrap: nowrap;
  color: #808184;
  font-size: 10px !important;
  text-transform: uppercase;
}

.chatWith {
  color: #E66433;
}


.age {
  font-family: 'Gill Sans Nova' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 17.21px !important;
  color: #808184;
}

.date {
  color: #808184 !important;
  font-family: 'Gill Sans Nova' !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: normal !important;
  letter-spacing: 2px !important;
  padding-top: 2px;
}

.itrowStatus {
  font-family: 'Myriad Pro' !important;
  font-style: 'normal' !important;
  font-weight: 400 !important;
}

.introwStatus {
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.accptDeclineBtns {
  display: flex;
  gap: 10px;
}

@media (width>=1800px) {
  .profileName {
    font-size: 32px !important;
  }

  .introwStatus {
    font-size: 24px !important;
  }

  .date {
    font-size: 22px !important;
  }
}

@media (width<=800px) {
  .profileName {
    font-size: 12px !important;
    max-width: 60%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: visible;
  }

  .dater_profileName {
    font-size: 12px !important;
    max-width: 60%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: visible;
  }

  .date {
    //max-width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .daterConvo {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
    margin-left: 50px;
  }
  .friend {
    margin-left: 30px !important;
    // margin-right: 20px;
  }
  .friend_name {
    margin-top: 5px !important;
  }
}

// @media (min-width: 430px) and (max-width: 430px) {
//   .dater_profile {
//     display: 'flex';
//     justify-content: end;
//     margin-right: 15px;
//   }
// }
