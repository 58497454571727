.root {
  //   background-color: #f6f6f6 !important;
  background-color: #000 !important;
  gap: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .friendImg {
    width: 90px !important;
    height: 90px !important;
    object-fit: cover !important;

    img {
      width: 80px !important;
    height: 80px !important;
    object-fit: cover !important;
      border-radius: 50% !important;
    }
  }

  .friendsFirstDiv {
    display: flex;
    gap: 10px;
  }

  .friendsSecDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    // height: 120px;
  }
}

.profileName {
  //   color: #00597b !important;
  color: #F4AA4D !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
}

.lastUpdate {
  //   color: #000 !important;
  color: #ffffff !important;
  font-family: 'Gill Sans' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
}


.description {
  overflow: hidden !important;
  // color: #000 !important;
  color: #fff !important;
  text-overflow: ellipsis !important;
  font-family: 'Gill Sans' !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-top: 12px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
}

.menuItem {
  color: #000 !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

@media (width>=1800px) {
  .profileName {
    font-size: 30px !important;
  }

  .lastUpdate {
    font-size: 24px !important;
  }

  .description {
    font-size: 24px !important;
  }

  .menuItem {
    font-size: 24px !important;
  }
}

@media (width<=450px) {
  .lastUpdate {
    font-size: 14px !important;
  }
}