.heading {
  // color: #8ec63f !important;
  color: #76d9ff !important;
  /* Heading S 3 */
  // font-family: 'Knuckle Down' !important;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  font-size: 32px !important;
}

.header {
  font-family: 'Gill Sans Nova' !important; // Ensure Gill Sans Nova is loaded or available
  font-weight: 900 !important; // Bold weight
  font-size: 14px !important; // Font size
  line-height: 15.62px !important; // Line height
  letter-spacing: 0.02em !important; // Letter spacing as a percentage of the font size (2%)
  color: #E66433;
  padding: 5px !important;
}

.subHeading {
  // color:  #05232E !important;
  color: #fff !important;
  /* P */
  font-size: 20px !important;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

@media (width>=1800px) {
  .heading {
    font-size: 48px !important;
  }
  .subHeading {
    font-size: 24px !important;
  }
  .header {
    font-size: 48px !important;
  }
}
