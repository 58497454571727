
.menuSelect{
    .MuiSelect-filled{
        font-size: 20px !important;
    }
}
@media (width>=1800px){
    .menuSelect{
        .MuiSelect-filled{
            font-size: 24px !important;
        }
    }
}