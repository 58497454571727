.pageName{
    color: #33196B !important;
    text-align: center !important;

    /* Heading S 2 */
    font-family: 'Knuckle Down' !important;
   // font-size: 32px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}