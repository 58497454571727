.description {
  // color: #333 !important;
  // color: #fff !important;
  color: #fff !important;
  font-family: 'Gill Sans Nova' !important;
  //font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  padding-left: 10px !important;
}

.name {
  color: #4D4D4F !important;
  border-radius: 50px !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  padding-left: 0px !important;
  padding-bottom: 2px !important; 
}

.name2 {
  color: #4D4D4F !important;
  border-radius: 16px !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  padding-left: 0px !important;
  padding-bottom: 2px !important; 
}


.shareApp {
  // color: #444 !important;
  color: #e66433 !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.addFriendLabel{
  margin-top: -120px !important;
}

.centerOnSmallScreen {
  width: 20%;
}

.mobileInputDiv {
  width: 80%;
}

.addFriend {
  //   color: #444 !important;
  // color: #fff !important;
  color: #76d9ff !important;
  /* Heading */
  font-family: 'Myriad Pro' !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.error {
  color: #E62A33 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 20px !important;
font-family: 'Gill Sans Nova' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}

.inviteBtn {
  button {
    min-width: 120px;
  }
}

.inputMobileNumber {
  height: 46px !important;
}

@media (width>=1800px) {
  .addFriend {
    font-size: 32px !important;
  }

  .inviteBtn {
    button {
      min-width: 120px;
      font-size: 24px;
    }
  }
}

@media (min-width: 620px) and (max-width: 1024px) {
  .centerOnSmallScreen {
    width: 28%;
  }

  .mobileInputDiv {
    width: 72%;
  }
}

@media (min-width: 830px) and (max-width: 1024px) {
  .centerOnSmallScreen {
    width: 30%;
  }

  .mobileInputDiv {
    width: 70%;
  }
}

@media (width<620px) {
  .centerOnSmallScreen {
    width: 37%;
  }

  .mobileInputDiv {
    width: 63%;
  }
}

@media (min-width: 1400px) and (max-width: 2500px) {
  .centerOnSmallScreen {
    width: 10%;
  }

  .mobileInputDiv {
    width: 90%;
  }
}

@media (width>=2500px) {
  .centerOnSmallScreen {
    width: 5%;
  }

  .mobileInputDiv {
    width: 95%;
  }
}

@media (min-width: 700px) {
  .addFriendLabel {
    margin-top: -90px !important;
  }
}