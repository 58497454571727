.sliderBox {
    border-bottom: 1px solid #C4C4C4;
    background: #F1F6F8;
    font-family: 'Gill Sans Nova' !important;
    // font-size: 16px;
    font-weight: 400 !important;
    // line-height: 24px;
}

.question {
    font-family: 'Gill Sans Nova' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    // line-height: normal !important;
    font-size: 18px !important;
    letter-spacing: 2% !important;
}

@media (width>=1800px){
    .question{
        font-size: 28px !important;
    }
}