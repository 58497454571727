.daterName {
  // color: #444 !important;
  color: #E66433 !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
}
.logo {
  margin-left: 15px;
}

@media (width>=1800px) {
  .daterName {
    font-size: 48px !important;
  }
}
