.header {
  // color: #444 !important;
  // color: #fff !important;
  color: #76d9ff !important;
  text-align: center !important;

  /* Heading */
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.shareApp {
  // color: #444 !important;
  color: #e66433 !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}


@media (width>=1800px) {
  .header {
    font-size: 48px !important;
    margin: 10px !important;
  }
}
