.description {
  // color: #05232E !important;
  // color: #fff !important;
  // background-color: #fff;
  color: #ffffff !important;
  color:#ECA43C !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 22px !important;
  width: 250px;
  font-style: normal !important;
  font-weight: 900 !important;
  // text-transform: uppercase !important;
  line-height: 24px !important; /* 166.667% */
  text-align: left !important;
}

.descriptionMessage {
  color: #ffffff !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 18px !important;
  width: 250px;
  font-style: normal !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  line-height: 24px !important; /* 166.667% */
  text-align: left !important;
}
.error {
  color: #E62A33 !important;
  line-height: 17.21px !important;
  letter-spacing: 0.12em !important;
  margin-left: 12px !important;



font-family: 'Myriad Pro' !important;
  // font-size: 18px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  // line-height: 40px !important; /* 166.667% */
  text-align: left !important;
}

.inputMobileNumber {
  border-bottom: 1px solid #c4c4c4 !important;
  background: #f1f6f8 !important;
  // height: 40px !important;
  font-family: 'Myriad Pro' !important;
  // font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border-radius: 50px !important;
}
