.nameAge {
  // color:  #00597B !important;
  color: #fff !important;
  text-align: left !important;
  font-size: 24px !important;
  /* Large Lebel */
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.message {
  // color: #000 !important;
  color: #fff !important;
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  font-size: 18px !important;
}

.elbowRequest {
  font-family: 'Myriad Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.userImg {
  width: 33px !important;
  height: 33px !important;
}
.elbowReq {
  display: flex;
  gap: 5px;
}

@media (width>=1800px) {
  .nameAge {
    font-size: 32px !important;
  }
  .elbowRequest {
    font-size: 24px !important;
  }
  .message {
    font-size: 24px !important;
  }
  .userImg {
    width: 42px !important;
    height: 42px !important;
  }
}

@media (width<=750px) {
  .remove_elbow {
    margin-top: -110px !important ;
  }
}
