.header {
  //styleName: Heading S 3;
  // // font-family: "Knuckle Down" !important;
  // font-family: 'Myriad Pro' !important;
  // font-weight: 400 !important;
  // letter-spacing: 0em !important;
  // // color: #FF000A !important;
  // color: #76d9ff;
  // font-size: 32px !important;
color: #E66433;
  font-family: 'Gill Sans Nova' !important;
font-size: 16px !important;
font-weight: 900 !important;
line-height: 18.22px !important;
letter-spacing: 0.02em !important;

}

.chatterboxImgListing {
  padding-top: 20px !important;
  display: flex;
  gap: 20px !important;
  justify-content: center;

  li {
    // align-items: center;
    justify-content: center;
    gap: 2px;
  }
}



.prospectiveDaterName {

  font-family: 'Gill Sans Nova' !important;
font-size: 12px !important;
font-weight: 900 !important;
line-height: 15.62px !important;
letter-spacing: 0.01em !important;
  text-align: center !important;
  white-space: pre-wrap !important;
  div {
    font-size: 12px !important;
  }
}

.prospectiveDaterAge {
  font-family: 'Gill Sans Nova' !important;
font-size: 12px !important;
font-weight: 500 !important;
line-height: 15.62px !important;
letter-spacing: 0.01em !important;
  text-align: center !important;
  white-space: pre-wrap !important;
  color: #cccccc !important;
  div {
    font-size: 12px !important;
  }
}

ul::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #8d8d8d;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #8d8d8d;
  -webkit-box-shadow: inset 0 0 6px #8d8d8d;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #8d8d8d;
}
.msgContainer {
  display: flex;
  align-items: center;
  // gap: 10px;
  justify-content: center;
}

.message {
  font-family: 'Gill Sans Nova' !important;
  font-weight: 500 !important;
  // line-height: normal !important;
  letter-spacing: 0em !important;
  font-size: 17px !important;
  // color: #fff !important;
  line-height: 16px !important;
  letter-spacing: 0.01em !important;
  overflow-wrap: break-word;
  max-width:75vw;
}
.chatDate {
  // color: #00000066;
  color: #fff !important;
  font-size: 16px !important;
}
.time {
  font-size: 12px !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  line-height: 14.31px !important;
  letter-spacing: 0.05em !important;
  color: #C5C5C5 !important;
  text-align: left;
}


.writeamessage {
  // color: #8d8d8d !important;
  color: #7f8082 !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  font-size: 10px !important;
  line-height: 11.48px;
  letter-spacing: 0.02em;
}

.inputField {
  background-color: #e7e7e7 !important;
  border-radius: 16px !important;
  border: 1px solid #cacaca !important;
}
.profilePic {
  height: 32px !important;
  width: 32px !important;
}



// @media (width>=1800px) {
//   .header {
//     font-size: 48px !important;
//   }
//   .prospectiveDaterName {
//     div {
//       font-size: 24px !important;
//     }
//   }
//   .message {
//     font-size: 24px !important;
//   }
//   .chatDate {
//     // color: #00000066;
//     color: #fff !important;
//     font-size: 20px !important;
//   }
//   .profilePic {
//     height: 36px !important;
//     width: 36px !important;
//   }
//   .writeamessage {
//     font-size: 28px !important;
//   }
// }

@media (width<=600px) {
  .msgContainer {
    padding: 10px 15px 10px 10px;
    position: fixed;
    // bottom: 85px;
    bottom: 66px;
    // height: 105px;
    background: black;
    margin-bottom: 0px;
  }
}

.chatterboxHorizontalFriendsScroller {
  @media screen and (max-width: 1800px) {
    max-width: 100vw !important;
  }
}