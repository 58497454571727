.heading{
    //styleName: Heading S 2;
font-family: 'Knuckle Down' !important;
font-weight: 400 !important;
letter-spacing: 0em !important;
text-align: center !important;
color:#00597B !important
}

.subHeading{
    font-family: 'Myriad Pro' !important;
    font-weight: 600 !important;
    letter-spacing: 0em !important;
    text-align: center !important;
}

.button{
    display: flex !important;
    border-radius: 25px !important;
   // padding: 12px 32px !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'Myriad Pro' !important;
   // font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
   // line-height: 24px !important; /* 133.333% */
    text-transform: capitalize !important;  
    color: #000000 !important;
}
.primary{
    background-color: #F93 !important;
    color: #000 !important;
}