.root {
  // background-color: #F6F6F6 !important;
  background-color: #000 !important;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.daterFirstDiv {
  display: flex;
  align-items: center;
  gap: 5px;
}
.daterSecDiv {
}

.profileName {
  color: #F4AA4D !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important; // Updated weight
  line-height: 28.64px !important; // Updated line height
  font-size: 22px !important; // Updated size
}

.lastUpdate {
  color: #fff !important;
  font-family: 'Gill Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 13.78px !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.04em !important; // Adjusted to 4%
}

.description {
  overflow: hidden !important;
  color: #000 !important;
  text-overflow: ellipsis !important;
  font-family: 'Myriad Pro' !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.menuItem {
  color: #000 !important;
  font-family: 'Myriad Pro' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

@media (width<=750px) {
  .daterSecDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -40px !important;
  }
}

@media (width>=1800px) {
  .profileName {
    font-size: 30px !important;
  }
  .lastUpdate {
    font-size: 18px !important;
  }
}
