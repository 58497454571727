.user-profile-wrapper {
  display: flex;
  // align-items: flex-end;
  align-items: center;
  gap: 5px;
  // margin-right: 10px;
  .user-type {
    font-family: 'Knuckle Down';
    font-weight: 600;
    color: blue;
    font-size: 28px;
    line-height: 20px;
  }
  .user-type-friend {
    font-family: 'Knuckle Down';
    font-weight: 600;
    // color: green;
    color: #f93;
    font-size: 28px;
    line-height: 20px;
  }

  img {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    object-fit: cover;
  }
}
