.root {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  //   padding-left: 15px;
  padding-right: 10px;
  padding-top: 5px;
  justify-content: space-between;
}
.reachOutText{
  margin-top: 0px !important;
}
.root:hover {
  //   background-color: #f2f2f2 !important;
  //   background-color: #adafbb9d !important;
}
.convosFirstDiv {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 65%;
}
.profileName {
  // color: #000 !important;
  color: #F4AA4D  !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
  // padding-bottom: 30px;
  font-size: 18px !important;
}

.stackClass {
  // color: #000 !important;
  color: #fff !important;
  /* Heading */
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  // padding-bottom: 30px;
  font-size: 22px !important;
}

.date {
  margin-top: 5px !important;
  color: #adafbb !important;
  text-align: right !important;
  font-family: 'Gill Sans Nova' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.introwStatus {
  font-family: 'Myriad Pro' !important;
  font-style: 'normal' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #fff;
}

.elbowStatus {
  font-family: 'Myriad Pro' !important;
  font-size: 16px;
  font-style: normal !important;
  font-weight: 400;
  line-height: 24px;
}

@media (width>=1800px) {
  .elbowStatus {
    font-size: 24px !important;
  }
  .profileName {
    font-size: 32px !important;
  }
  .introwStatus {
    font-size: 20px !important;
  }
  .date {
    font-size: 20px !important;
  }
}
