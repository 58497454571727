.root {
  // background-color: #F6F6F6 !important;
  background-color: #000 !important;
  gap: 5px;
  .addFriendImg {
    width: 100px;
    height: 90px;
    p {
      width: 100%;
      height: 100%;
    }
    img {
      // width: 100%;
      // height: 100%;
    }
  }
}

.addFriend {
  //   color: #00597b !important;
  // color: #fff !important;
  color: #F4AA4D !important;
  font-family: 'Gill Sans Nova' !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: normal !important;
}

@media (width>=1800px) {
  .addFriend {
    font-size: 32px !important;
  }
}
