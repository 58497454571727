.listItemTxt{
    .MuiListItemText-primary{
    font-size: 18px;
    }
}

@media (width>=1800px){
    .listItemTxt{
        .MuiListItemText-primary{
        font-size: 24px !important;
    }
    }
}