.chip{
    padding: 8px 12px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    color: #05232E !important;
    text-align: center !important;
    font-family: 'Myriad Pro' !important;
    // font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}
.chipLabels{
    font-size: 16px;
    margin: -5px !important;
    span{        
    height: auto !important;
        padding: 8px 12px !important;
    }
}
.customChipCls{
    .chip{
        font-size: 16px;
    }
}

@media (width>=1800px){
    .chipLabels{
        span{
            padding: 14px 20px !important;
        }
        font-size: 28px !important;
        height: auto !important;
    }
    .customChipCls{
        .chip{
            font-size: 24px !important;
        }
    }
}