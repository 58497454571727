.startHeader {
  font-family: 'Gill Sans Nova' !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 29px !important;
  text-align: left !important;
  letter-spacing: 8% !important;
  word-spacing: 0.10em !important;
  // color:#05232E !important;
  color: #fff !important;
  text-transform: uppercase !important;
  // color: #76d9ff !important;
  text-align: left !important;
  letter-spacing: 0.11em !important;
}
.logoContainer {
}
.logo {
  height: 135px;
}

.btnsWrapper {
  @media (max-width: 700px) {
    justify-content: center !important;
    gap: 20px;
  }
}
.btnCreateAccount {
  // padding: 12px 32px !important;
  border-radius: 25px !important;
  border: 1px solid #E72933 !important;
  font-family: 'Gill Sans Nova' !important;
  //  font-size: 18px !important;
  font-style: normal !important;
  letter-spacing: 4% !important;
  font-weight: 900 !important;
  //  line-height: 24px !important; /* 133.333% */
  text-transform:none !important;
}

// @media (width >=1800px) {
//   .startHeader {
//     font-size: 42px !important;
//     line-height: unset !important;
//   }
//   .btnCreateAccount {
//     font-size: 32px !important;
//     padding: 20px 32px !important;
//   }
//   .complianceText {
//     font-size: 24px !important;
//     line-height: 40px !important;
//   }
//   .btnSignin {
//     font-size: 32px !important;
//     padding: 20px 32px !important;
//   }
// }
.complianceText {
  // color: #000 !important;
  color: #BCBEC0 !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  // line-height: 20px !important; /* 142.857% */
  text-align: left !important;
  a {
    color: #00BEFF !important;
  }
}
.btnSignin {
  // padding: 12px 32px !important;
  justify-content: left !important;
  border-radius: 25px !important;
  background: #e66433 !important;
  color: #ffffff !important;
  font-family: 'Gill Sans Nova' !important;
  // font-size: 18px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  //  line-height: 24px !important; /* 133.333% */
  text-transform: none !important;
}
